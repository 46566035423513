import Vue from 'vue'
import App from './App'
import i18n from './i18n'
import vuetify from '@/plugins/vuetify'

Vue.config.productionTip = process.env.NODE_ENV === 'production'

Vue.config.errorHandler = function (err, vm, info) {
  console.log('--> Global error handler', err)
  console.log('--> Global error handler', vm)
  console.log('--> Global error handler', info)
}

/**
 * Valores iniciales
 */
const el = document.getElementById('help-app')
Vue.prototype.$assetRelativePath = el.getAttribute('data-assetRelativePath')
Vue.prototype.$languageId = el.getAttribute('data-languageId')
Vue.prototype.$icon = el.getAttribute('data-icon') === 'true'
Vue.prototype.$version = el.getAttribute('data-version')

/**
 * i18N
 */
i18n.locale = Vue.prototype.$languageId.split(/[-_]+/)[0]

const app = new Vue({
  vuetify,
  i18n,
  render: h => h(App)
})

app.$mount('#help-app')
