export default {
  help: 'Axuda',
  title: 'Servizo de atención aos usuarios',
  email: 'Correo electrónico',
  phones: 'Teléfonos',
  countries: {
    es: 'España',
    mx: 'México',
    ch: 'Chile',
    co: 'Colombia',
    sv: 'O Salvador',
    pe: 'Perú',
    ar: 'Arxentina',
    us: 'Estados Unidos',
    rd: 'República Dominicana'
  }
}
