export default {
  help: 'Ajuda',
  title: 'Serviço de Atenção aos Usuários',
  email: 'E-mail',
  phones: 'Telefones',
  countries: {
    es: 'Espanha',
    mx: 'México',
    ch: 'Chile',
    co: 'Colômbia',
    sv: 'El Salvador',
    pe: 'Peru',
    ar: 'Argentina',
    us: 'United States',
    rd: 'República Dominicana'
  }
}
