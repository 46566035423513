export default {
  help: 'Help',
  title: 'Service of Attention to Users',
  email: 'Email',
  phones: 'Phones',
  countries: {
    es: 'Spain',
    mx: 'Mexico',
    ch: 'Chile',
    co: 'Colombia',
    sv: 'El Salvador',
    pe: 'Peru',
    ar: 'Argentina',
    us: 'United States',
    rd: 'Dominican Republic'
  }
}
