<template>
  <v-container>
    <v-dialog v-model="showHelp" persistent width="650" scrollable>
      <template v-slot:activator="{ on: onShowHelp }">
        <v-tooltip bottom attach>
          <template v-slot:activator="{ on: onTooltip }">
            <v-btn
              pr-4
              v-bind:icon="icon"
              v-bind:class="icon ? '' : 'show-help'"
              v-on="{ ...onTooltip, ...onShowHelp }"
              color="secondary lighten-2"
              text
              small>
              <v-img max-width="30" v-bind:src="assetRelativePath + 'images/asistencia.png'" contain></v-img>
              <span v-if="!icon" max-width="30"> {{ $t('help') }} </span>
            </v-btn>
          </template>
          {{ $t('help') }}
        </v-tooltip>
      </template>
      <v-card>
        <v-card-title>
            <v-img  max-width="47" v-bind:src="assetRelativePath + 'images/asistencia.png'" contain></v-img>
          <span class="mayuscula colorAzulOscuroNegrita paL15">{{ $t('title') }}</span>
          <v-spacer></v-spacer>
          <v-btn v-on:click="showHelp = false" color="#FFFFFF" fab small>
            <v-icon color="#CCCCCC">close</v-icon>
          </v-btn>

        </v-card-title>

        <v-card-text>
           <v-divider class="bordeAzulClaro"></v-divider>
          <!-- CENTRO-->
         <v-container class="paT30">
            <v-layout row wrap>
                <v-flex xs6 class="paL15 paT30">
                     <v-flex xs12>
                         <v-icon color="#CCCCCC">email</v-icon>
                     </v-flex>
                     <v-flex xs12 v-for="email in emails" v-bind:key="email">
                         <span class="bordeSuperior colorAzulOscuro frutiger">{{ (email) }}</span>
                     </v-flex>
                </v-flex>
                 <v-flex xs5>
                    <v-img  max-width="280" v-bind:src="assetRelativePath + 'images/mapamundi2.png'" contain></v-img>
                </v-flex>
            </v-layout>
          </v-container>
           <!-- CENTRO-->
          <!-- TELEFONOS -->
          <v-container>
            <v-layout row wrap class="paL15">
              <v-flex xs6 md4 v-for="phone in phones" v-bind:key="phone.id" class="paT15">
                 <v-flex xs12>
                     <span class="mayuscula colorGrisClaro">{{ $t(phone.label) }}</span>
                 </v-flex>
                 <v-flex xs12 class="colorAzulOscuroNegrita">
                    <span >{{ (phone.value) }}</span>
                 </v-flex>
              </v-flex>
            </v-layout>
          </v-container>
          <!-- TELEFONOS -->
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <span class="frutiger colorGrisClaro"> {{ $version }} </span>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
export default {
  name: 'BaseApp',
  props: {
    icon: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      assetRelativePath: process.env.BASE_URL + this.$assetRelativePath,
      showHelp: false,
      emails: ['soporte@gobertia.com'],
      phones: [
        { id: 'es', label: 'countries.es', value: '+34 910 48 28 28' },
        { id: 'pe', label: 'countries.pe', value: '+51 1 640 9742' },
        { id: 'sv', label: 'countries.sv', value: '+503 2136 9065' },
        { id: 'ch', label: 'countries.ch', value: '+56 2 3210 8246' },
        { id: 'co', label: 'countries.co', value: '+57 1 382 6779' },
        { id: 'ar', label: 'countries.ar', value: '+54 (11) 5235 4315' },
        { id: 'us', label: 'countries.us', value: '+1 202 754 8406' },
        { id: 'mx', label: 'countries.mx', value: '+52 (55) 4169 6918' },
        { id: 'rd', label: 'countries.rd', value: '+ 1 (829) 2496024',
        },
      ]
    }
  },
  created: function () {
    console.log('created BaseApp')
  }
}
</script>
