<template>
  <v-app class="container white" role="main" id="v-help-app">
      <BaseApp v-bind:icon="$icon"></BaseApp>
  </v-app>
</template>

<script>
import BaseApp from './components/BaseApp'
export default {
  name: 'help-app',
  components: { BaseApp },
  created: function () {
  }
}

</script>
<style lang='scss'>
    @import 'scss/app.scss';
</style>
