export default {
  help: 'Ajut',
  title: 'Servei d’Atenció als Usuaris',
  email: 'Correu electrònic',
  phones: 'Telèfons',
  countries: {
    es: 'Espanya',
    mx: 'Mèxic',
    ch: 'Xile',
    co: 'Colòmbia',
    sv: 'El Salvador',
    pe: 'Perú',
    ar: 'Argentina',
    us: 'Estats Units',
    rd: 'República Dominicana'
  }
}
