export default {
  help: 'Hilfe',
  title: 'Service der Aufmerksamkeit für Benutzer',
  email: 'E-Mail',
  phones: 'Telefone',
  countries: {
    es: 'Spanien',
    mx: 'Mexiko',
    ch: 'Chile',
    co: 'Kolumbien',
    sv: 'El Salvador',
    pe: 'Peru',
    ar: 'Argentinien',
    us: 'Vereinigte Staaten',
    rd: 'Dominikanische Republik'
  }
}
