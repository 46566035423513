// src/plugins/vuetify.js

// for mdi import '@mdi/font/css/materialdesignicons.css' mdi npm install @mdi/font -D
// for md 'material-design-icons-iconfont/dist/material-design-icons.css'  npm install material-design-icons-iconfont -D
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import Vue from 'vue'
import Vuetify from 'vuetify'

Vue.use(Vuetify)

const opts = {
  icons: {
    iconfont: 'md'
  },
  theme: {
    options: {
      customProperties: true
    }
  }
}

export default new Vuetify(opts)
