import Vue from 'vue'
import VueI18n from 'vue-i18n'
import ca from './ca'
import de from './de'
import en from './en'
import es from './es'
import eu from './eu'
import fr from './fr'
import gl from './gl'
import pt from './pt'

Vue.use(VueI18n)

const messages = {
  ca: ca,
  de: de,
  en: en,
  es: es,
  eu: eu,
  fr: fr,
  gl: gl,
  pt: pt
}
/*
function extend (Vue) {
  Object.defineProperty(Vue.prototype, '$t', {
    get: function get () {
        return 0;
    }
  });
 }
*/
export default new VueI18n({
  locale: 'es',
  messages
})
