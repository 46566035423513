export default {
  help: 'Laguntza',
  title: 'Erabiltzaileei arreta emateko zerbitzua',
  email: 'Emaila',
  phones: 'Telefono ',
  countries: {
    es: 'Espainiako',
    mx: 'Mexikon',
    ch: 'Chile',
    co: 'Kolonbian',
    sv: 'El Salvador',
    pe: 'Peru',
    ar: 'Argentina',
    us: 'Ameriketako Estatu Batuak',
    rd: 'Dominikar Errepublika'
  }
}
