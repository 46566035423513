export default {
  help: 'Aide',
  title: 'Service d\'attention aux utilisateurs',
  email: 'Courriel',
  phones: 'Téléphones',
  countries: {
    es: 'Espagne',
    mx: 'Mexique',
    ch: 'Chili',
    co: 'Colombie',
    sv: 'Le Salvador',
    pe: 'Pérou',
    ar: 'Argentine',
    us: 'États Unis',
    rd: 'République Dominicaine'
  }
}
